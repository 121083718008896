<template>
    <div>
        <LiefengContent v-if="!this.$route.query.columnCode && !this.$route.query.functionType">
            <template v-slot:title>{{ menuCodeObj.menuName || "图文类信息" }}</template>
            <template v-slot:toolsbarLeft>
                <Button type="primary" icon="ios-cube-outline" @click="openNumCount" style="margin-right: 10px" v-if="buttonRoot == '1003'">行为数据分析</Button>
                <Button icon="ios-add" type="primary" v-if="buttonRoot == '1001'" @click="addChange">新增</Button>
            </template>
            <template v-slot:toolsbarRight>
                <Form :model="searchData" :label-colon="true" :inline="true" class="search">
                    <FormItem label="标题" :label-width="50" style="margin-bottom: 0">
                        <Input v-model.trim="searchData.title" placeholder="请输入标题" style="width: 160px"></Input>
                    </FormItem>
                    <FormItem label="发布范围" :label-width="75">
                        <CascaderCity @changeCasader="changeCasader" :resetNum="resetNum" :orgCode="4401" :width="200"></CascaderCity>
                    </FormItem>
                    <FormItem label="拟制人" :label-width="60">
                        <Input v-model.trim="searchData.creatorName" style="width: 160px"></Input>
                    </FormItem>
                    <Button type="primary" icon="ios-search" @click="searchBtn" style="margin-right: 10px">查询</Button>
                    <Button type="success" @click="resetBtn" icon="ios-refresh" style="margin-right: 10px">重置</Button>
                </Form>
            </template>
            <template v-slot:contentArea>
                <div class="table-left">
                    <Menu theme="light" :active-name="activeMenu" @on-select="selectMenu">
                        <MenuGroup title="信息状态">
                            <MenuItem name="2" v-if="buttonRoot == '1001'">待提交/已驳回({{ rejectNumber }})</MenuItem>
                            <MenuItem name="1" v-if="buttonRoot == '1002'">待审核({{ auditNumber }})</MenuItem>
                            <MenuItem name="3" v-if="buttonRoot == '1003'">待发布({{ waitNumber }})</MenuItem>
                            <MenuItem name="4">
                                已发布
                                <Tooltip placement="right" content="已发布的内容居民在小程序、APP等终端可正常查看到。"><Icon type="ios-help-circle-outline" size="20" /></Tooltip>
                            </MenuItem>
                            <MenuItem name="5">
                                已过期
                                <Tooltip placement="right" content="到了截止时间内容则为已过期，在小程序的全部和往期回顾中可查看到。"><Icon type="ios-help-circle-outline" size="20" /></Tooltip>
                            </MenuItem>
                            <MenuItem name="6">
                                已归档
                                <Tooltip placement="right" content="归档后的内容在小程序、APP等终端则不显示，对用户不可见。"><Icon type="ios-help-circle-outline" size="20" /></Tooltip>
                            </MenuItem>
                            <MenuItem name="7">全部信息</MenuItem>
                        </MenuGroup>
                    </Menu>
                </div>
                <LiefengTable
                    :talbeColumns="talbeColumns"
                    :tableData="tableData"
                    :loading="loading"
                    :fixTable="true"
                    :curPage="page"
                    :total="total"
                    :pagesizeOpts="[20, 30, 50, 100]"
                    :page-size="pageSize"
                    @hadlePageSize="hadlePageSize"
                    @tableSelect="tableSelect"
                ></LiefengTable>
            </template>
        </LiefengContent>
        <!--新增修改-->
        <LiefengModal :title="titleName" :value="contrastStatus" @input="contrastFn" :fullscreen="true" class="none-modal">
            <template v-slot:contentarea>
                <Imgtext ref="affaris" :Type="affairsType" :formData="formData" :buttonRoot="buttonRoot" :columnCode="columnCode" :showNum="showNum"></Imgtext>
            </template>
            <template v-slot:toolsbar>
                <Button id="file" type="info" style="margin-right: 10px" :disabled="passDisable" @click="passRow(6)" v-if="(affairsType != 'check' || affairsType != 'add') && affairsType == 'file'">
                    归档
                </Button>
                <Button id="old" type="info" style="margin-right: 10px" :disabled="passDisable" @click="passRow(5)" v-if="(affairsType != 'check' || affairsType != 'add') && affairsType == 'old'">
                    过期
                </Button>
                <Button id="publish" type="info" style="margin-right: 10px" :disabled="passDisable" @click="passRow(4)" v-if="buttonRoot == '1003' && affairsType == 'send'">发布</Button>
                <Button id="adopt" type="info" style="margin-right: 10px" :disabled="passDisable" @click="passRow(3)" v-if="buttonRoot == '1002' && affairsType == 'examine'">通过</Button>
                <Button
                    id="reject"
                    type="error"
                    v-if="(buttonRoot == '1002' || buttonRoot == '1003') && (affairsType == 'examine' || affairsType == 'send')"
                    style="margin-right: 10px"
                    :disabled="passDisable"
                    @click="passRow(2)"
                >
                    驳回
                </Button>
                <Button id="urgent" type="warning" style="margin-right: 10px" v-if="buttonRoot == '1002' && affairsType == 'examine'" @click="urgent(3)">通过并加急发布</Button>
                <Button id="cancel" type="info" style="margin-right: 10px" @click="contrastFn(false)">取消</Button>
                <Button id="enter" v-if="affairsType != 'examine' && affairsType != 'send'" type="primary" @click="contrastSave" :disabled="saveDisabled">确定</Button>
            </template>
        </LiefengModal>
        <!-- 分享并预览 -->
        <LiefengModal title="分享并预览" :value="officialStatus" @input="officialStatusFn" height="calc(100vh - 200px)" class="signInOut" width="1146px">
            <template v-slot:contentarea>
                <QrCode v-if="officialStatus" :functionType="functionType" :codeString="codeString" :infoId="qrCodeId" ref="QrCode" :qrOCdeName="qrOCdeName"></QrCode>
            </template>
        </LiefengModal>
        <!--更换栏目-->
        <LiefengModal title="更换栏目" :value="ChangeColumn" @input="ChangeColumnFn" :fullscreen="false">
            <template v-slot:contentarea>
                <div style="height: 200px">
                    <Cascader style="width: 50%; padding-top: 80px; margin-left: 120px" :data="cascaderData" v-model="cascadValue"></Cascader>
                </div>
            </template>
            <template v-slot:toolsbar>
                <Button
                    type="info"
                    @click="
                        cancelModelTip = true
                        ChangeColumnFn(false)
                    "
                    style="margin-right: 10px"
                >
                    取消
                </Button>
                <Button type="primary" @click="changeSave">保存</Button>
            </template>
        </LiefengModal>
        <!--用户操作记录-->
        <LiefengModal title="用户操作记录" :value="userChooseTle" @input="UserChooseFn" :fullscreen="true">
            <template v-slot:></template>
            <template v-slot:contentarea>
                <CheckRecord :businessType="sendMessage.businessType" :infoId="sendMessage.infoId" :isShow="isOpenUserMessage" @userSave="userSave"></CheckRecord>
            </template>
        </LiefengModal>
        <!-- 更换栏目 -->
        <LiefengModal title="更换栏目" :value="synchroniZationStatus" @input="synchroniZationStatusFn" :fullscreen="false">
            <template v-slot:contentarea>
                <Synchron v-if="synchroniZationStatus" :infoId="synchronId" :functionType="menuCodeObj.functionType" ref="Synchron"></Synchron>
            </template>
            <template v-slot:toolsbar>
                <Button
                    type="info"
                    @click="
                        synchroniZationStatusTip = true
                        synchroniZationStatusFn(false)
                    "
                    style="margin-right: 10px"
                >
                    取消
                </Button>
                <Button type="primary" @click="saveSynchroniZation">保存</Button>
            </template>
        </LiefengModal>
        <!-- 相关链接 -->
        <LiefengModal title="相关链接" :value="aboutLinkStatus" @input="aboutLinkStatusFn" :fullscreen="true">
            <template v-slot:contentarea>
                <AboutLink ref="aboutLink" @saveSuccess="saveSuccess" v-if="aboutLinkStatus" :menuCodeObj="menuCodeObj" :infoId="aboutInfo" :infoCode="infoCode"></AboutLink>
            </template>
            <template v-slot:toolsbar>
                <Button
                    type="info"
                    @click="
                        cancelModalTip = true
                        aboutLinkStatusFn(false)
                    "
                    style="margin-right: 10px"
                >
                    取消
                </Button>
                <Button type="primary" @click="aboutLinkSave" :disabled="saveDisabled">保存</Button>
            </template>
        </LiefengModal>

        <!--行为数据分析-->
        <LiefengModal title="行为数据分析" :value="numFenxiStatus" @input="numFenxiFn" :fullscreen="true">
            <template v-slot:contentarea>
                <Analysis :isTrue="isTrue" :infoIdLis="infoIdLis"></Analysis>
            </template>
            <template v-slot:toolsbar>
                <Button type="primary" @click="numFenxiFn(false)">关闭</Button>
            </template>
        </LiefengModal>
        <!-- 驳回原因 -->
        <LiefengModal title="驳回原因" :value="rejectStatus" @input="changeReject" width="600px">
            <template v-slot:contentarea>
                <Input id="reason" v-model="reason" rows="7" type="textarea" />
            </template>
            <template v-slot:toolsbar>
                <Button
                    id="cancelreject"
                    style="margin-right: 10px"
                    type="primary"
                    @click="
                        changeReject(false)
                        reason = ''
                    "
                >
                    取消
                </Button>
                <Button id="enterrejection" type="error" @click="enterReject">驳回</Button>
            </template>
        </LiefengModal>

        <!-- 新增模板 -->
        <LiefengModal title="模板" :value="templateStatus" @input="changeTemplateStatus" width="1200px" height="625px">
            <template v-slot:contentarea>
                <Templatetable
                    @addShowNum="addShowNum"
                    @setFormData="setFormData"
                    ref="Templatetable"
                    @changeAffairsType="changeAffairsType"
                    @contrastFn="contrastFn"
                    :columnCode="columnCode"
                ></Templatetable>
            </template>
            <template v-slot:toolsbar>
                <Button style="margin-right: 10px" type="primary" @click="changeTemplateStatus(false)">关闭</Button>
            </template>
        </LiefengModal>

        <!-- 关联数据 -->
        <LiefengModal title="关联数据" :value="relationStatus" @input="changeRelationStatus" :fullscreen="true">
            <template v-slot:contentarea>
                <Relation ref="relation" :quesDate="quesDatef" :showNum="showRNum"></Relation>
            </template>
            <template v-slot:toolsbar>
                <Button style="margin-right: 10px" type="primary" @click="changeRelationStatus(false)">关闭</Button>
            </template>
        </LiefengModal>

        <!-- 推送 -->
        <LiefengModal title="推送" :value="pushStatus" @input="changePushStatus" width="400px">
            <template v-slot:contentarea>
                <Form>
                    <FormItem>
                        <span slot="label" class="validate">机构名称</span>
                        <Input style="width: 300px" v-model="pushObj.orgName" :maxlength="20" disabled placeholder="机构名称"></Input>
                    </FormItem>
                    <FormItem>
                        <span slot="label" class="validate">通知内容</span>
                        <Input style="width: 300px" v-model="pushObj.title" :maxlength="20" placeholder="请输入通知内容"></Input>
                    </FormItem>
                </Form>
            </template>
            <template v-slot:toolsbar>
                <Button style="margin-right: 10px" type="primary" @click="customPush">推送</Button>
                <Button style="margin-right: 10px" type="primary" @click="changePushStatus(false)">关闭</Button>
            </template>
        </LiefengModal>

        <!-- 操作模态框 -->
        <LiefengModal class="caozuo-modal" title="操作" :value="caozuoStatus" width="800" @input="caozuoStatusFn">
            <template #contentarea>
                <div class="content-div">
                    <div class="module-item">
                        <div class="title">核心数据</div>
                        <div class="import-div">
                            <div class="left" v-if="dimensionId == 2">
                                <div class="num">
                                    <span class="blue">{{ caozuoInfo.registerViewUser || 0 }}</span>
                                    |
                                    <span class="none">{{ caozuoInfo.registerUser || 0 }}</span>
                                    <span class="red">{{ caozuoInfo.registerUserScale || 0 }}</span>
                                </div>
                                <div class="desc">整体阅读情况</div>
                            </div>
                            <div class="right" style="position: relative">
                                <div class="num">
                                    <span class="blue">{{ caozuoInfo.weixinMemberView || 0 }}</span>
                                    |
                                    <span class="none">{{ caozuoInfo.weixinMember || 0 }}</span>
                                    <span class="red">{{ caozuoInfo.weixinMemberScale || 0 }}</span>
                                </div>
                                <div class="desc">微信群用户阅读情况</div>

                                <div style="position: absolute; top: 5px; right: 10px; color: blue" @click="changeMore(caozuoInfo.id)">详情</div>
                            </div>
                            <div class="text">
                                <p>整体阅读情况：全部阅读量与发布数据时的注册用户的占比，重要信息要求阅读量大于60%</p>
                                <p>微信群用户阅读情况：通过分享到微信群的信息，点击查看的阅读人数与微信群总人数的占比</p>
                            </div>
                        </div>
                    </div>
                    <div class="module-item">
                        <div class="title">重要功能</div>
                        <div class="fun-btns">
                            <div class="btn-box" v-for="(item, index) in caozuoList" :key="index" @click="changeOtherItem(item)">{{ item.name }}</div>
                        </div>
                    </div>
                    <div class="module-item" v-if="caojiList && caojiList.length">
                        <div class="title">采集信息</div>
                        <div class="fun-btns">
                            <div class="btn-box" v-for="(item, index) in caojiList" :key="index" @click="changeOtherItem(item)">{{ item.name }}</div>
                        </div>
                    </div>
                    <div class="module-item">
                        <div class="title">推送通知</div>
                        <div class="fun-btns">
                            <div class="btn-box" @click="changeOpenSendModal">{{ "精准推送" }}</div>
                            <div class="btn-box" @click="jumpSendDetail">{{ "推送记录" }}</div>
                        </div>
                    </div>
                    <div class="module-item" v-if="caozuoInfo.functionType != '24' && caozuoInfo.functionType != '25' && caozuoInfo.functionType != '17'">
                        <div class="title">内容设置</div>
                        <div class="set-content">
                            <Form :label-colon="true" :label-width="120">
                                <FormItem label="发布终端">
                                    <span slot="label">发布终端:</span>
                                    <Checkbox
                                        @on-change="
                                            val => {
                                                changeCheck(val, item)
                                            }
                                        "
                                        v-model="item.select"
                                        v-for="(item, index) in channelList"
                                        :key="index"
                                        :label="item.label"
                                    >
                                        {{ item.value }}
                                    </Checkbox>
                                </FormItem>
                                <FormItem>
                                    <span slot="label">推荐到首页:</span>
                                    <RadioGroup v-model="setContent.recommend">
                                        <Radio label="0">否</Radio>
                                        <Radio label="1">是</Radio>
                                    </RadioGroup>
                                </FormItem>
                                <FormItem label="联系电话">
                                    <Input v-model.trim="setContent.contactMobile" style="width: 200px" :maxlength="20"></Input>
                                </FormItem>
                                <!-- <FormItem label="小程序推送">
                                    <CheckboxGroup v-model="setContent.pushType" @on-change="changePushType">
                                        <Checkbox label="1">我的用户</Checkbox>
                                        <span style="color: #aaa; width: 200px; margin-left: 16px">注：勾选后，订阅了社区通知的服务过的居民可收到推送信息。</span>
                                        <br />
                                        <Checkbox label="2">我的管理员</Checkbox>
                                        <span style="color: #aaa; width: 200px; margin-left: 16px">注：勾选后，订阅了社区通知的员工档案在职管理员可收到推送信息。</span>
                                        <br />
                                        <Checkbox label="3">我的畅联组</Checkbox>
                                        <span v-if="setContent.pushType && setContent.pushType[0] == 3">
                                            <Input style="width: 300px; margin-left: 16px" v-model="setContent.groupNames" />
                                            <Button type="primary" @click="groupStatus = true">选群</Button>
                                        </span>
                                        <br />
                                        <Checkbox label="4" v-if="dimensionId == 2 || buttonRoot == '1003'">社区线上用户</Checkbox>
                                        <span v-if="dimensionId == 2 || buttonRoot == '1003'" style="color: #aaa; width: 200px; margin-left: 16px">注：勾选后，订阅了社区通知的所有线上居民可收到推送信息。</span>
                                    </CheckboxGroup>
                                </FormItem> -->
                            </Form>
                        </div>
                    </div>
                    <div class="module-item" v-if="caozuoInfo.functionType != '24' && caozuoInfo.functionType != '25' && caozuoInfo.functionType != '17'">
                        <div class="title">标签设置</div>
                        <div class="set-content">
                            <Form :label-colon="true" :label-width="120">
                                <FormItem>
                                    <span slot="label">内容标签:</span>
                                    <CheckboxGroup v-model="setContent.tipsCodeList">
                                        <Checkbox v-for="(item, index) in typeList" :key="index" :label="item.dictKey">{{ item.dictValue }}</Checkbox>
                                    </CheckboxGroup>
                                </FormItem>
                            </Form>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:toolsbar>
                <Button type="info" style="margin-right: 20px" @click="caozuoStatusFn(false)">取消</Button>
                <Button type="primary" @click="setSave" v-if="caozuoInfo.functionType != '24' && caozuoInfo.functionType != '25' && caozuoInfo.functionType != '17'">确定</Button>
            </template>
        </LiefengModal>

        <!-- 推送选群 -->
        <LiefengModal :value="groupStatus" title="选择畅联组" :fullscreen="true" @input="changeGroupStatus">
            <template v-slot:contentarea>
                <CheckGroup :buttonRoot="buttonRoot" ref="checkGroup" @getGroup="getGroup"></CheckGroup>
            </template>
            <template v-slot:toolsbar>
                <!-- <Button type="info" @click="cancelGroup" style="margin-right:10px"> 取消 </Button> -->
                <Button type="primary" @click="saveGroup">保存</Button>
            </template>
        </LiefengModal>

        <!-- 推送选群 -->
        <LiefengModal :value="sendGroupStatus" title="推送对象设置" :fullscreen="true" @input="changeGroupStatus2">
           <template v-slot:contentarea>
                <Form :label-colon="true" :label-width="120">
                    <FormItem label="推送对象设置">
                        <RadioGroup v-model="sendGroup.pushChannel" @on-change="changePushChannel">
                            <Radio label="1">我的用户</Radio>
                            <span style="color: #aaa; width: 200px; margin-left: 16px">注：勾选后，订阅了社区通知的服务过的居民可收到推送信息。</span>
                            <span style="color: #19be6b; margin-left: 10px">{{ coreData.userNum }}人</span>
                            <br />
                            <Radio label="2">我的管理员</Radio>
                            <span style="color: #aaa; width: 200px; margin-left: 16px">注：勾选后，订阅了社区通知的员工档案在职管理员可收到推送信息。</span>
                            <span style="color: #19be6b; margin-left: 10px">{{ coreData.custNum }}人</span>
                            <br />
                            <Radio label="3">我的畅联组</Radio>
                            <span style="color: #aaa; width: 200px; margin-left: 16px">注：选择群后，群成员中订阅了社区通知的用户均可收到推送信息。</span>
                            <br />
                            <Radio label="5" v-if="dimensionId == 2 || buttonRoot == '1003'">社区注册用户</Radio>
                            <span v-if="dimensionId == 2 || buttonRoot == '1003'" style="color: #aaa; width: 200px; margin-left: 16px">
                                注：勾选后，订阅了社区通知且 激活了社区（村）地址的居民用户可收到推送信息。
                            </span>
                            <span v-if="dimensionId == 2 || buttonRoot == '1003'" style="color: #19be6b; margin-left: 10px">{{ coreData.registerNum }}人</span>
                            <br v-if="dimensionId == 2 || buttonRoot == '1003'" />
                            <Radio label="4" v-if="dimensionId == 2 || buttonRoot == '1003'">社区访问用户</Radio>
                            <span v-if="dimensionId == 2 || buttonRoot == '1003'" style="color: #aaa; width: 200px; margin-left: 16px">
                                注：勾选后，订阅了社区通知的所有访问过本社区的用户可收到推送信息。
                            </span>
                            <span v-if="dimensionId == 2 || buttonRoot == '1003'" style="color: #19be6b; margin-left: 10px">{{ coreData.viewNum }}人</span>
                            <br v-if="dimensionId == 2 || buttonRoot == '1003'" />

                            <Radio label="7" v-if="dimensionId == 2 || buttonRoot == '1003'">指定居民数据库人群</Radio>
                            <span v-if="dimensionId == 2 || buttonRoot == '1003'" style="color: #aaa; width: 200px; margin-left: 16px">
                                注：勾选后，订阅了社区通知且激活了账号的居民数据库报表人群可收到推送信息。
                            </span>
                            <br v-if="dimensionId == 2 || buttonRoot == '1003'" />

                            <Radio label="10">数采服务系统报表</Radio>
                            <span style="color: #aaa; width: 200px; margin-left: 16px">注：勾选后，订阅了社区通知且激活了账号的数采服务系统报表人员可收到推送信息。</span>
                            <br />
                            
                        </RadioGroup>
                    </FormItem>
                </Form>
                <div class="container-div" v-show="sendGroup.pushChannel && (sendGroup.pushChannel == '3' || sendGroup.pushChannel == '7' || sendGroup.pushChannel == '10')">
                    <div class="center-table">
                        <div class="table">
                            <LiefengTable
                                :talbeColumns="grouptalbeColumns"
                                :tableData="grouptableData"
                                :loading="grouploading"
                                :fixTable="true"
                                :curPage="grouppage"
                                :total="grouptotal"
                                :pagesizeOpts="[20, 30, 50, 100]"
                                :page-size="grouppageSize"
                                @hadlePageSize="grouphadlePageSize"
                                @tableSelect="grouptableSelect"
                                class="group-table"
                                ref="lftable"
                                :hidePage="sendGroup.pushChannel && sendGroup.pushChannel == '7'"
                            ></LiefengTable>
                        </div>
                    </div>
                    <div class="right-table">
                        <div class="right-center">
                            <Button type="primary" style="margin-bottom: 14px" @click="pushToRightList">右移</Button>
                            <Button type="error" style="margin-bottom: 14px" @click="delRightList">删除</Button>
                            <Button type="warning" @click="clearRightList">清空</Button>
                        </div>

                        <div class="right-right">
                            <div style="text-align: center; font-size: 14px; font-weight: bold; line-height: 40px; color: #515a6e; background: #f8f8f9">已选择</div>
                            <List border size="small">
                                <ListItem v-for="(item, index) in rightList" :key="item.id">
                                    <div style="width: 100%; display: block; cursor: pointer" @click="selectRightList($event, index)">
                                        {{ item.groupName }}
                                    </div>
                                </ListItem>
                            </List>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:toolsbar>
                <Button type="info" @click="changeGroupStatus2(false)" style="margin-right: 10px">取消</Button>
                <Button type="primary" @click="saveSendGroup">精准推送</Button>
            </template>
        </LiefengModal>
    </div>
</template>

<script>
import CascaderCity from "../components/CascaderCity"
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import { myMixin } from "../utils/utilsmixin.js"
import Imgtext from "../childrens/imgtext.vue"
import QrCode from "../childrens/qrCode.vue"
import CheckRecord from "../childrens/checkRecord"
import AboutLink from "../childrens/aboutLink"
import Synchron from "../childrens/synchron"
import Analysis from "../childrens/analysis"
import Templatetable from "../childrens/templatetable"
import Relation from "../childrens/relationdata"
import CheckGroup from "../childrens/checkgroup"

export default {
    components: { CascaderCity, LiefengContent, LiefengTable, LiefengModal, Imgtext, QrCode, CheckRecord, AboutLink, Synchron, Analysis, Templatetable, Relation, CheckGroup },
    mixins: [myMixin],
    data() {
        return {
            // 存放分页接口的链接
            pageUrl: "/info/api/pc/information/v2/queryDataPage",
            // table表格数据
            talbeColumns: [
                {
                    type: "selection",
                    width: 60,
                    align: "center",
                },
                {
                    title: "信息名称",
                    key: "title",
                    minWidth: 200,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    color: "#2d8cf0",
                                    cursor: "pointer",
                                    userSelect: "none",
                                    textAlign: "center",
                                },
                                on: {
                                    click: () => {
                                        this.titleName = "查看"
                                        this.affairsType = "check"
                                        this.getDetail(params.row.id)
                                    },
                                },
                            },
                            params.row.title
                        )
                    },
                },

                {
                    title: "发布范围",
                    key: "releaseScope",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "公布时效",
                    key: "startTime",
                    width: 200,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.startTime + " 到 " + params.row.endTime)
                    },
                },

                {
                    title: "推荐",
                    key: "recommend",
                    width: 165,
                    align: "center",
                    render: (h, params) => {
                        return (
                            "div",
                            [
                                h(
                                    "RadioGroup",
                                    {
                                        attrs: {
                                            value: params.row.recommend,
                                        },
                                        on: {
                                            "on-change": status => {
                                                let data = {
                                                    id: params.row.id,
                                                    recommend: status,
                                                    sort: params.row.sort,
                                                    functionType: params.row.functionType,
                                                    status:params.row.status,
                                                    terminal:params.row.terminal
                                                }
                                                this.changeStatusRow(data)
                                            },
                                        },
                                    },
                                    [
                                        h(
                                            "Radio",
                                            {
                                                attrs: {
                                                    label: "1",
                                                    disabled: params.row.status == "4" ? false : true,
                                                },
                                            },
                                            "推荐"
                                        ),
                                        h(
                                            "Radio",
                                            {
                                                attrs: {
                                                    label: "0",
                                                    disabled: params.row.status == "4" ? false : true,
                                                },
                                            },
                                            "不推荐"
                                        ),
                                    ]
                                ),
                            ]
                        )
                    },
                },
                {
                    title: "排序",
                    key: "sort",
                    width: 110,
                    align: "center",
                    render: (h, params) => {
                        return h("InputNumber", {
                            props: {
                                min: 1,
                                max: 999999999,
                                value: parseInt(params.row.sort),
                                precision: 0,
                            },
                            style: {
                                width: "100%",
                            },
                            on: {
                                "on-change": val => {
                                    if (val == params.row.sort) return
                                    let data = {
                                        id: params.row.id,
                                        recommend: params.row.recommend,
                                        sort: val,
                                        functionType: params.row.functionType,
                                        status:params.row.status,
                                        terminal:params.row.terminal
                                    }
                                    clearTimeout(this.timer)
                                    this.timer = setTimeout(() => {
                                        this.changeStatusRow(data)
                                    }, 1000)
                                },
                            },
                        })
                    },
                },
                {
                    title: "审核状态",
                    key: "status",
                    minWidth: 120,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "div",
                            {},
                            params.row.status
                                ? params.row.status == "1"
                                    ? "待审核"
                                    : params.row.status == "2"
                                    ? "已驳回"
                                    : params.row.status == "3"
                                    ? "待发布"
                                    : params.row.status == "4"
                                    ? "已发布"
                                    : params.row.status == "6"
                                    ? "已归档"
                                    : params.row.status == "5"
                                    ? "已过期"
                                    : ""
                                : ""
                        )
                    },
                },
                {
                    title: "信息编码",
                    key: "code",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "操作",
                    align: "center",
                    fixed: "right",
                    width: 180,
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    textAlign: "center",
                                },
                            },
                            [
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: () => {
                                                this.titleName = "修改"
                                                this.affairsType = "edit"
                                                this.getDetail(params.row.id)
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            display: this.buttonRoot == "1001" ? "" : "none",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "修改" //1001
                                ),
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: () => {
                                                this.officialStatus = true
                                                this.qrCodeId = params.row.id
                                                this.qrOCdeName = params.row.title
                                                this.codeString = params.row.id
                                                this.functionType = params.row.functionType
                                                this.$nextTick(() => {
                                                    this.$refs.QrCode.getOfficialScopeList(this.qrCodeId, "2", "44")
                                                })
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "二维码" //1001
                                ),
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: () => {
                                                this.titleName = "审核"
                                                this.affairsType = "examine"
                                                this.getDetail(params.row.id)
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            display: this.buttonRoot == "1002" && params.row.status == "1" ? "" : "none",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "审核" //1002
                                ),
                                h(
                                    "span",
                                    {
                                        attrs: {
                                            type: "info",
                                            size: "small",
                                        },
                                        on: {
                                            click: () => {
                                                this.titleName = "发布"
                                                this.affairsType = "send"
                                                this.getDetail(params.row.id)
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            display: this.buttonRoot == "1003" && params.row.status == "3" ? "" : "none",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "发布" //1003
                                ),
                                h(
                                    "Dropdown",
                                    {
                                        props: {
                                            transfer: true,
                                        },
                                    },
                                    [
                                        h(
                                            "span",
                                            {
                                                // class: "operation-title",
                                                style: {
                                                    color: "#2d8cf0",
                                                    cursor: "pointer",
                                                    userSelect: "none",
                                                    position: "relative",
                                                    display: "flex",
                                                },
                                                on: {
                                                    click: async () => {
                                                        this.$Message.loading({
                                                            background: true,
                                                            content: "正在加载，请稍等...",
                                                        })
                                                        await this.$get("/info/api/pc/information/v2/queryById", {
                                                            infoId: params.row.id,
                                                            businessType: params.row.businessType,
                                                            functionType: params.row.functionType,
                                                            oemCode: parent.vue.oemInfo.oemCode,
                                                            operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                                                            orgCode: parent.vue.loginInfo.userinfo.orgCode,
                                                            terminal: 2,
                                                        }).then(res => {
                                                            this.$Message.destroy()
                                                            if (res.code == 200) {
                                                                this.caozuoInfo = res.data
                                                                if (res.data) {
                                                                    let list1 = []
                                                                    let list2 = []
                                                                    if (res.data.status != "6") {
                                                                        list1.push({ name: "归档", type: "1" })
                                                                    }
                                                                    if (res.data.status == "4") {
                                                                        list1.push({ name: "过期", type: "2" })
                                                                    }
                                                                    if (this.buttonRoot == "1001") {
                                                                        list1.push({ name: "复制", type: "3" })
                                                                    }
                                                                    list1.push(
                                                                        { name: "删除", type: "4" },
                                                                        { name: "更换栏目", type: "5" },

                                                                        { name: "更新公布时间", type: "23" }
                                                                    )
                                                                    if(parent.vue.loginInfo.userinfo.master){
                                                                        list2.push({ name: "用户操作记录", type: "10" })
                                                                    }
                                                                    if (this.isManager == "1") {
                                                                        list1.push({ name: "设为模板", type: "6" })
                                                                    }
                                                                    if (res.data.status == 4 || res.data.status == 5) {
                                                                        list1.push({ name: "关联数据", type: "7" })
                                                                        if (res.data.functionType == "17") {
                                                                            list1.push(
                                                                                { name: "答题记录", type: "11" },
                                                                                { name: "中奖名单", type: "12" },
                                                                                { name: "抽奖名单", type: "13" },
                                                                                { name: "排行榜", type: "14" }
                                                                            )
                                                                        }
                                                                        if (res.data.whetherDynamicForm == "1") {
                                                                            if (res.data.functionType == "24") {
                                                                                if (res.data.appointmentVo.appointmentType != 1) {
                                                                                    list1.push({ name: "采集信息", type: "15" })
                                                                                }
                                                                            }
                                                                        }
                                                                        if (res.data.functionType == "24") {
                                                                            if (res.data.appointmentVo.appointmentType == 3 && res.data.status == 4 && res.data.functionType == 24) {
                                                                                list1.push({ name: "报名时段管理", type: "18" })
                                                                            }
                                                                        }

                                                                        if (res.data.functionType == "25") {
                                                                            list1.push({ name: "采集信息", type: "15" })
                                                                        }
                                                                        if (res.data.prizeVos && res.data.prizeVos.length && res.data.functionType == "24") {
                                                                            list1.push({ name: "中奖名单", type: "12" })
                                                                        }
                                                                        if (res.data.functionType == "24") {
                                                                            if (res.data.functionType == "24" && res.data.appointmentVo.appointmentType == 4) {
                                                                                list1.push({ name: "排号记录", type: "21" })
                                                                            }
                                                                        }
                                                                    }
                                                                    if (res.data.functionType != "15") {
                                                                        list1.push({ name: "配置相关链接", type: "8" })
                                                                    }
                                                                    if (res.data.status == "4") {
                                                                        list1.push({ name: "拟制机构推送", type: "9" })
                                                                    }
                                                                    if (res.data.functionType == "24" || res.data.functionType == "25") {
                                                                        list1.push({ name: "通知记录", type: "16" })
                                                                        if (res.data.status == 4 && res.data.functionType == "24") {
                                                                            list2.push({ name: "报名记录", type: "19" })
                                                                        }
                                                                    }
                                                                    if (res.data.appointmentVo && JSON.stringify(res.data.appointmentVo) != '{}' && res.data.appointmentVo.whetherSign == 1) {
                                                                        list1.push({ name: "签到码", type: "20" })
                                                                    }

                                                                    list2.push({ name: "畅联组应读已读记录", type: "22" })
                                                                    list2.push({ name: "指定人群应读已读记录", type: "24" })
                                                                    
                                                                    this.caozuoList = list1
                                                                    this.caojiList = list2
                                                                }
                                                                this.getContent()
                                                                this.caozuoStatusFn(true)
                                                            } else {
                                                                this.$Message.error({
                                                                    content: "获取数据详情失败",
                                                                    background: true,
                                                                })
                                                                return
                                                            }
                                                        })
                                                    },
                                                },
                                            },
                                            "操作"
                                        ),
                                    ]
                                ),
                            ]
                        )
                    },
                },
            ],
            // 新增修改模态框状态
            contrastStatus: false,

            titleName: "", //标题,

            // 存储点击的是什么类型得按钮
            affairsType: "",
            formData: {
                // title:'',
                // source:'',
                // date:[],
                // subType:'',
                // contactMobile:'',
                // priority:'',
                // terminal:[]
            },
            // 将参数传给组件
            columnCode: "",

            // 重置组件
            showNum: 0,

            // 用户详情接口
            detailMessage: {},
            //排序定时器
            timer: null,

            // 确定按钮禁选状态
            saveDisabled: false,

            // 通过驳回状态
            passDisable: false,

            // 分享并预览模态框状态
            officialStatus: false,

            // 存放点击分享并预览的时候点击的当前的infoId
            qrCodeId: "",
            qrOCdeName: "", //信息标题
            codeString: "", // 传给组件的code

            // 更换栏目模态框状态
            ChangeColumn: false,
            cancelModelTip: false,
            cascaderData: [],
            cascadValue: [],
            changeColumnRow: {},

            // 用户操作记录模态框状态
            userChooseTle: false,
            sendMessage: {},
            modelTitle: "", // 模态框标题

            // 配置相关链接模态框状态
            aboutLinkStatus: false,
            aboutInfo: "",
            infoCode: "",

            // 同步栏目模态框状态
            synchroniZationStatus: false,
            synchroniZationStatusTip: false,
            synchronId: "",

            // 选择的题目
            selectList: [],
            infoIdLis: "",
            isTrue: 0,
            numFenxiStatus: false,

            // 驳回原因
            rejectStatus: false,
            reason: "",

            // 模板
            templateStatus: false,

            //关联数据
            relationStatus: false,
            quesDatef: {},
            showRNum: 0,

            //推送
            pushStatus: false,
            pushObj: {},
        }
    },
    created() {},
    methods: {
        // 方法部分

        // 行为数据模态框关闭事件
        numFenxiFn(status) {
            this.numFenxiStatus = status
        },
        // 点击行为数据按钮
        openNumCount() {
            if (this.selectList.length == 0) {
                this.$Message.warning({
                    content: "请选择后进行点击",
                    background: true,
                })
                return
            } else {
                this.numFenxiStatus = true
                this.isTrue += 1
                let List = []
                this.selectList.map(item => {
                    List.push(item.id)
                })
                this.infoIdLis = List.join(",")
            }
        },
        // 选择表格事件
        tableSelect(val) {
            this.selectList = val
        },
        // 更换栏目确认按钮事件
        async saveSynchroniZation() {
            let list = this.$refs.Synchron.$refs.tree.getCheckedNodes().filter(item => item.columnCode)
            console.log("list", list)
            if (!list || !list.length) {
                this.$Message.warning({
                    content: "请勾选一个栏目",
                    background: true,
                })
            } else {
                this.$post(
                    "/info/api/pc/information/v2/changeColumn",
                    {
                        informationId: this.synchronId,
                        columnCode: list[list.length - 1].columnCode,
                        subColumnCode: list[list.length - 1].subColumnCode,
                    },
                    { "Content-Type": "application/json" }
                ).then(res => {
                    if (res.code == 200) {
                        this.$Message.success({
                            content: "更换栏目成功",
                            background: true,
                        })
                        this.synchroniZationStatus = false
                        this.synchroniZationStatusTip = false
                        this.page = this.currentPage
                        this.getList()
                    } else {
                        this.$Message.error({
                            content: "更换栏目失败",
                            background: true,
                        })
                    }
                })
            }
        },
        // 同步栏目
        synchroniZationStatusFn(status) {
            if (!status && this.synchroniZationStatusTip) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "您正在关闭此窗口，信息将不会保存，是否继续",
                    onOk: () => {
                        this.synchroniZationStatus = status
                    },
                })
            } else {
                this.synchroniZationStatus = status
            }
        },
        // 相关链接
        aboutLinkStatusFn(status) {
            if (!status && this.cancelModalTip) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "您正在关闭此窗口，信息将不会保存，是否继续",
                    onOk: () => {
                        this.aboutLinkStatus = status
                    },
                })
            } else {
                this.aboutLinkStatus = status
            }
        },
        aboutLinkSave() {
            this.$refs.aboutLink.save()
        },
        // 新增修改成功后执行
        saveSuccess() {
            this.saveDisabled = false
            this.cancelModalTip = false
            this.aboutLinkStatus = false
            this.hadlePageSize({
                page: this.page,
                pageSize: this.pageSize,
            })
        },
        //用户操作关闭模态框操作
        UserChooseFn(status) {
            this.userChooseTle = status
        },
        // 用户操作记录保存
        userSave(status) {
            this.userChooseTle = status
            this.isOpenUserMessage = false
        },
        // 更换栏目保存方法
        changeSave() {
            var menuId = ""
            if (this.cascadValue.length == 0) {
                this.$Message.warning({
                    content: "请选择后进行保存！",
                    background: true,
                })
                return
            } else {
                menuId = this.cascadValue.pop()
            }
            this.changeColumnRowUpload(menuId)
        },
        // 更换栏目模态框关闭事件
        // 点击关闭模态框的方法
        ChangeColumnFn(status) {
            if (!status && this.cancelModelTip) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "<p>确认要关闭窗口吗？未保存的信息不会保存</p>",
                    onOk: () => {
                        this.ChangeColumn = status
                        this.cascadValue = []
                    },
                })
            } else {
                this.ChangeColumn = status
            }
        },
        // 分享并预览模态框关闭事件
        officialStatusFn(status) {
            this.officialStatus = status
        },
        // 通过
        passRow(status) {
            if (status == 2) {
                this.rejectStatus = true
                return
            }
            let data = {
                id: this.detailMessage.id,
                recommend: this.detailMessage.recommend,
                sort: this.detailMessage.sort,
                pushChannel: this.formData.pushChannel,
                pushType: this.formData.pushType && this.formData.pushType[0] ? this.formData.pushType[0] : "",
                channels: this.formData.pushType && this.formData.pushType[0] == 3 ? this.formData.channels : "",
                status,
            }
            this.changeExan(data)
        },
        // 点击了新增按钮
        // addChange() {
        //     this.templateStatus = true
        //     this.columnCode = this.menuCodeObj.columnCode
        //     this.$nextTick(() => {
        //         this.$refs.Templatetable.getList()
        //     })
        //     this.titleName = "新增"
        //     ++this.showNum
        // },
        // // 关闭弹窗的方法
        // contrastFn(status) {
        //     this.contrastStatus = status
        // },
        // 新增修改保存按钮
        contrastSave() {
            if (this.affairsType == "check") {
                this.contrastStatus = false
                return
            }
            let data = this.$refs.affaris
            this.formData.operator = {
                businessType: this.menuCodeObj.businessType,
                functionType: this.menuCodeObj.functionType,
                oemCode: parent.vue.oemInfo.oemCode,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                // terminal:'2'
            }
            data.$refs.formData.validate(valid => {
                if (valid) {
                    if (this.formData.date.length == 0 || !this.formData.date[0]) {
                        this.$Message.warning({
                            content: "请选择公布时效",
                            background: true,
                        })
                        return
                    }
                    if (!data.cityOper.prov || data.cityOper.prov.length == 0) {
                        this.$Message.warning({
                            content: "请选择发布范围",
                            background: true,
                        })
                        return
                    }
                    if (!this.formData.terminal || this.formData.terminal == "") {
                        this.$Message.warning({
                            content: "请选择发布终端",
                            background: true,
                        })
                        return
                    }
                    if (!this.formData.photo) {
                        this.$Message.warning({
                            content: "请上传封面图片",
                            background: true,
                        })
                        return
                    } else {
                        if (data.cityOper.prov && data.cityOper.prov.length != 0) {
                                this.formData.dataScopeIdList = data.cityOper.prov
                                if (data.cityOper.city && data.cityOper.city.length != 0) {
                                    this.formData.dataScopeIdList = data.cityOper.city
                                    if (data.cityOper.region && data.cityOper.region.length != 0) {
                                        this.formData.dataScopeIdList = data.cityOper.region
                                        if (data.cityOper.stree && data.cityOper.stree.length != 0) {
                                            this.formData.dataScopeIdList = data.cityOper.stree
                                            if (data.cityOper.project && data.cityOper.project.length != 0) {
                                                this.formData.dataScopeIdList = data.cityOper.project
                                            }
                                        }
                                    }
                                }
                            }
                    }
                    if (document.getElementById("ueditor").contentWindow.getContent() == "") {
                        this.$Message.warning({
                            content: "请填写详细内容",
                            background: true,
                        })
                        return
                    } else {
                        this.formData.content = document.getElementById("ueditor").contentWindow.getContent()
                    }
                    if (this.formData.date && this.formData.date.length != 0 && this.formData.date[0]) {
                        this.formData.startTime = this.$core.formatDate(new Date(this.formData.date[0]), "yyyy-MM-dd hh:mm:ss")
                        this.formData.endTime = this.$core.formatDate(new Date(this.formData.date[1]), "yyyy-MM-dd hh:mm:ss")
                    }

                    // delete this.formData.date

                    // if (this.formData.startTime == "" || this.formData.endTime == "") {
                    //     delete this.formData.startTime
                    //     delete this.formData.endTime
                    // }
                    // if(this.formData.pushType == 3 && !this.formData.channels){
                    //     this.$Message.warning({
                    //         content: "请]选择畅聊群",
                    //         background: true,
                    //     })
                    //     return
                    // }
                    if (this.formData.pushType && this.formData.pushType.length != 0) this.formData.pushType = this.formData.pushType[0]
                    else this.formData.pushType = ""
                    if (this.affairsType == "add" || this.affairsType == "quote") {
                        // 如果是新增的
                        this.createNews()
                        // console.log(this.formData)
                        return
                    } else if (this.affairsType == "edit") {
                        if (this.formData.startTime && this.formData.startTime != "") this.formData.startTime = this.$core.formatDate(new Date(this.formData.startTime), "yyyy-MM-dd hh:mm:ss")
                        if (this.formData.endTime && this.formData.endTime != "") this.formData.endTime = this.$core.formatDate(new Date(this.formData.endTime), "yyyy-MM-dd hh:mm:ss")
                        this.formData.status = "1"
                        this.updateNews()
                        return
                    }
                } else {
                    this.$Message.warning({
                        content: "请填写对应的数据",
                        background: true,
                    })
                    return
                }
            })
        },

        // 驳回弹窗
        changeReject(val) {
            this.rejectStatus = val
        },
        // 确认驳回
        enterReject() {
            if (!this.reason.trim()) {
                this.$Message.warning({
                    content: "请填写驳回原因",
                    background: true,
                })
                return
            }
            let data = {
                reason: this.reason,
                id: this.detailMessage.id,
                recommend: this.detailMessage.recommend,
                sort: this.detailMessage.sort,
                status: 2,
                pushChannel: this.detailMessage.pushChannel,
            }
            console.log(parent.vue.loginInfo.userinfo)
            this.changeExan(data)
        },
        // 模板框状态
        changeTemplateStatus(val) {
            if (!val) {
                this.$refs.Templatetable.templateName = ""
            }
            this.templateStatus = val
        },
        setFormData(val) {
            this.detailMessage = val
            this.formData = val
            let obj = JSON.parse(JSON.stringify(val))
            if (obj.mapRos && obj.mapRos.length != 0) {
                obj.longitude = obj.mapRos[0].longitude + "," + obj.mapRos[0].latitude
            }
            this.formData = obj
            this.formData.source = parent.vue.loginInfo.userinfo.orgName
            this.contrastStatus = true
            this.changeAffairsType("quote")
            ++this.showNum
            this.columnCode = this.menuCodeObj.columnCode
            if (document.getElementById("ueditor") && this.formData.content) {
                document.getElementById("ueditor").contentWindow.setContent(this.formData.content)
            }
        },
        changeAffairsType(val) {
            this.affairsType = val
        },
        addShowNum() {
            ++this.showNum
            this.formData = {
                source: parent.vue.loginInfo.userinfo.orgName,
                terminal: "0",
                pushChannel: "0",
            }
        },

        // 关联数据弹窗
        changeRelationStatus(val) {
            this.relationStatus = val
        },

        // 推送窗口
        changePushStatus(val) {
            this.pushStatus = val
        },
    },
}
</script>
<style lang="less">
.operation-title {
    &::after {
        content: ">";
        position: "absolute";
        transform: rotate(90deg);
        display: block;
        margin-left: 2px;
        font-size: 10px;
    }
}
</style>
<style lang="less" scoped>
// /deep/#toolsbarRight {
//   width: 1000%;
//   .search {
//     width: 100%;
//     text-align: right;
//     .ivu-form-item {
//       margin-bottom: 0;
//     }
//   }
// }
.table-left {
    float: left;
    width: 160px;
    /deep/.ivu-menu {
        width: 160px !important;
    }
}
.none-modal {
    /deep/.ivu-modal-close {
        display: none;
    }
}
/deep/.ivu-input-type-textarea {
    textarea {
        resize: none;
    }
}
/deep/.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
/deep/.ivu-tooltip-inner {
    width: 200px;
    white-space: normal;
}
/deep/.ivu-menu-item {
    z-index: 3;
}
.signInOut {
    /deep/ #modal_contentarea {
        // width: 1146px !important;
    }
    /deep/.ivu-modal-close {
        display: block;
    }
}
.content-div {
    .module-item {
        margin: 10px 20px;
        box-sizing: border-box;

        > .title {
            font-size: 16px;
            font-weight: bold;
            line-height: 40px;
            color: #287aad;
        }
        .fun-btns {
            display: flex;
            flex-wrap: wrap;

            .btn-box {
                cursor: pointer;
                margin-right: 7px;
                margin-bottom: 7px;
                width: calc(100% / 4 - 7px + 7px / 4);
                border: 1px solid #f3f3f3;
                height: 35px;
                border-radius: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                background: #f6f6f6;

                &:nth-child(4n) {
                    margin-right: 0;
                }
            }
        }
    }
    .import-div {
        display: flex;
        user-select: none;
        cursor: pointer;
        .left {
            width: 50%;
            border: 1px solid #ccc;
            margin-right: 10px;
            border-radius: 5px;
            padding: 10px 20px;
            height: 80px;
            text-align: center;
        }
        .right {
            width: 50%;
            border: 1px solid #ccc;
            margin-right: 10px;
            border-radius: 5px;
            padding: 10px 20px;
            height: 80px;
            text-align: center;
        }
        .desc {
            margin-top: 10px;
        }
        .blue {
            color: #287aad;
            font-weight: bold;
            margin-right: 5px;
        }
        .none {
            font-weight: bold;
            margin-left: 5px;
        }
        .red {
            margin-left: 20px;
            font-weight: bold;
            color: red;
        }
    }
}
.btn-view {
    display: flex;
    justify-content: center;
}
.caozuo-modal {
    /deep/ #modal_contentarea {
        max-height: 500px;
        overflow-y: scroll;
    }
}
.group-table {
    /deep/.ivu-table-wrapper {
        height: 500px !important;
    }
    /deep/.ivu-table-body{
        height: 460px !important;
    }
}
.container-div{
  height: 100%;
  display: flex;
  width: 100%;
  .tree-div{
    width: 200px;
    height: 100%;
    /deep/.ivu-tree {
      height: calc(100vh - 155px);
      overflow: auto;
    }
  }
  .center-table{
    width: calc(100% - 430px);
    margin-left: 20px;
    // border: 1px solid red;
  }
  .right-table{
    margin-left: auto;
    width:410px;
    height: 500px;
    display: flex;
    .right-center{
      width: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .right-right{
      width: 300px;
      border: 1px solid #dcdee2; 
      overflow: auto;
    }
  }
  /deep/.active {
      background: #19be6b;
      color: #fff;
    }
}
</style>